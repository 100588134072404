// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
// This Spree 4.3.0 relies on Turbolinks.
// We can use turbo manually by using data-turbo=true
// See: https://github.com/hotwired/turbo-rails#navigate-with-turbo-drive
import { Turbo } from "@hotwired/turbo-rails"
import "channels"
import "controllers"

Turbo.session.drive = false
Rails.start()
Turbolinks.start()
ActiveStorage.start()
