/**
 * This controller's purpose is to clone two fields: card number and cvv.
 * Reasaon being is that these tow fields get cleared by CardLock after
 * receiving the token. We need the cvv.
 */
import { Controller } from 'stimulus'; 
export default class extends Controller {
  
  static targets = [ 'clone_cvv', 'clone_number', 'submitButton']

  cloneCvv(event) {
    this.clone_cvv.value = event.target.value
  }

  cloneNumber(event) {
    this.clone_numberTarget.value = event.target.value
    // Spree adds spaces in numbers, we remove them for Cardlock
    const trimmed = this.clone_numberTarget.value.replace(/\s+/g, '')
    this.clone_numberTarget.value = trimmed

    // Also remove the disable attribute if customer enters an invalid card number.
    // CardLock disables the input when pan is invalid
    this.submitButtonTarget.disabled = false
  }
}