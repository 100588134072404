import { Controller } from 'stimulus'; 
import Swiper, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';

export default class extends Controller {
    static targets = [ 'pagination', 'next', 'prev']

    connect() {
        let swiper = new Swiper(this.element, {
            modules: [Navigation, Pagination, EffectFade, Autoplay],
            effect: "fade",
            centeredSlides: true,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },
            pagination: {
                el: this.paginationTarget,
                clickable: true,
                dynamicBullets: true
            },
            navigation: {
                nextEl: this.nextTarget,
                prevEl: this.prevTarget,
            }
        });
    }
}